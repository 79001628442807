<template>
  <div
    v-visibility-change="visibilityChange"
    class="row table-sm mr-0 ml-0 p-0"
  >
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.forms_of_documents") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <el-button
          v-can="'documentations.create'"
          size="mini"
          @click="dialogOpen()"
        >
          <i class="el-icon-circle-plus-outline"></i> {{ $t("message.create") }}
        </el-button>
        <!-- <export-excel
          v-can="'documentations.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Типы расхода "
          name="Типы_расхода.xls"
        >
          <el-button size="mini">
            <i class="el-icon-document-delete"></i> Excel
          </el-button>
        </export-excel> -->
        <crm-column-settings
          :columns="columns"
          :modelName="'formTemplate'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.name"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.category_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.updated_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.name.show">
            <el-input
              size="mini"
              v-model="filterForm.name"
              :placeholder="columns.name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.category_id.show">
            <el-input
              size="mini"
              :disabled="true"
              :placeholder="columns.name.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.updated_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.updated_at"
              :placeholder="columns.updated_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr v-for="service in list" :key="service.id">
          <td v-if="columns.id.show">{{ service.id }}</td>
          <td v-if="columns.name.show">{{ service.name }}</td>
          <td v-if="columns.category_id.show">
            {{ service.category ? service.category.name : "" }}
          </td>
          <td v-if="columns.created_at.show">
            {{ service.created_at }}
          </td>
          <td v-if="columns.updated_at.show">
            {{ service.updated_at }}
          </td>
          <td>
            <crm-setting-dropdown
              :model="service"
              name="formTemplate"
              :actions="actions"
              @edit="edit"
              @delete="destroyModel"
            >
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-dialog
      title="Создание шаблона"
      :append-to-body="true"
      :visible.sync="outerVisible"
      width="40%"
    >
      <el-row :gutter="20" style="text-align: center">
        <div style="display: inline-block" class="title_h mr-3">
          <el-select
            size="small"
            filterable
            clearable
            v-model="form.category_id"
            :placeholder="$t('message.choose_category')"
          >
            <el-option
              v-for="(item, index) in categories"
              :key="'category' + index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div style="display: inline-block" class="title_h mr-3">
          <el-input
            v-model="form.name"
            size="small"
            :placeholder="$t('message.name_template')"
          ></el-input>
        </div>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="openEditor" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()"> {{$t('message.close')}} </el-button>
      </div>
    </el-dialog>
    <el-drawer
      title="Новый Формы Документов "
      :visible.sync="drawerCreate"
      size="100%" :wrapperClosable="false"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      title="Изменить Формы Документов "
      :visible.sync="drawerUpdate"
      size="100%" :wrapperClosable="false"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  name: "formTemplateList",
  components: {
    CrmUpdate,
    CrmCreate,
  },
  data() {
    return {
      drawerCreate: false,
      outerVisible: false,
      drawerUpdate: false,
      reopenUpdate: false,
      excel_data: [],
      form: {},
      excel_fields: {},
      loadingData: false,
      backUrl: process.env.VUE_APP_URL_DOCS,
    };
  },
  computed: {
    ...mapGetters({
      list: "formTemplate/list",
      columns: "formTemplate/columns",
      pagination: "formTemplate/pagination",
      filter: "formTemplate/filter",
      categories: "categoryDocumentation/list",
      sort: "formTemplate/sort",
      token: "auth/token",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  async mounted() {
    this.controlExcelData();

    if (this.categories && this.categories.length == 0) {
      this.categoryList();
    }
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateSort: "formTemplate/updateSort",
      updateFilter: "formTemplate/updateFilter",
      updateColumn: "formTemplate/updateColumn",
      updateList: "formTemplate/index",
      updatePagination: "formTemplate/updatePagination",
      editModel: "formTemplate/show",
      empty: "formTemplate/empty",
      delete: "formTemplate/destroy",
      refreshData: "formTemplate/refreshData",
      categoryList: "categoryDocumentation/index",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    visibilityChange(evt, hidden) {
      // do something
      if (!hidden) this.fetchData();
    },
    dialogClose() {
      this.outerVisible = false;
      this.form = {};
    },
    dialogOpen() {
      this.outerVisible = true;
    },
    openEditor() {
      var link = document.createElement("a");

      link.setAttribute(
        "href",
        this.backUrl +
          "/documentation_file_orientation?name=" +
          this.form.name +
          "&user=" +
          this.token +
          "&category_id=" +
          this.form.category_id +
          "&status=create"
      );
      link.setAttribute("target", "_blank");
      link.click();

      this.dialogClose();
      return;
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      var link = document.createElement("a");

      link.setAttribute(
        "href",
        this.backUrl +
          "/documentation_file_orientation?name=" +
          model.name +
          "&user=" +
          this.token +
          "&id=" +
          model.id +
          "&category_id=" +
          model.category_id +
          "&status=edit"
      );
      link.setAttribute("target", "_blank");
      link.click();

      this.dialogClose();
      return;
      this.$loadingCursor("wait");
      await this.editModel(model.id)
        .then(async (res) => {
          this.$loadingCursor("default");
          this.drawerUpdate = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
